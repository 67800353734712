.bonus-list {
  display: grid;
  grid-template: 1fr / repeat(3, 1fr);
  gap: 0 8px;
  width: 100%;
  margin-bottom: 20px;

  &__item {
    display: grid;
    grid-template: auto auto / auto;
    justify-items: center;
    padding: 9px 8px;
    background-color: var(--c-dark-grey);
    border-radius: 14.5px;
    font-size: 1.35rem;
    font-weight: 700;
    text-align: center;
    line-height: 1.5;

    &:nth-child(1) {
      color: #F7460F;
    }

    &:nth-child(2) {
      color: #00FF66;
    }

    &:nth-child(3) {
      color: #FFB130;
    }
  }
}

span.coin-dollar {
  padding-left: 24px;
  background:
    url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHJlY3Qgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiB4PSIxIiB5PSIxIiBmaWxsPSIjRkZCMTMwIiByeD0iOCIvPjxyZWN0IHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgeD0iMiIgeT0iMiIgZmlsbD0idXJsKCNhKSIgcng9IjciLz48cGF0aCBmaWxsPSIjMTIxRDQxIiBkPSJNOC44IDEwLjg3di0xLjhjLS41NjItLjE2LS45NzQtLjQwMi0xLjIzNy0uNzI1LS4yNjEtLjMyNi0uMzkxLS43MjEtLjM5MS0xLjE4NSAwLS40Ny4xNDctLjg2My40NDItMS4xODEuMjk4LS4zMi42OTMtLjUwNSAxLjE4NS0uNTU0VjVoLjYyM3YuNDI1Yy40NTUuMDU0LjgxNy4yMSAxLjA4Ni40NjguMjcuMjU1LjQ0MS41OTcuNTE1IDEuMDI2bC0xLjA4Ni4xNDJjLS4wNjYtLjMzOC0uMjM4LS41NjctLjUxNS0uNjg3djEuNjc5Yy42ODcuMTg2IDEuMTU1LjQyOCAxLjQwNC43MjYuMjQ5LjI5NS4zNzMuNjc0LjM3MyAxLjEzOCAwIC41MTgtLjE1Ny45NTQtLjQ3MiAxLjMxLS4zMTIuMzU0LS43NDcuNTcyLTEuMzA1LjY1MnYuODAzaC0uNjIzVjExLjljLS40OTUtLjA2LS44OTctLjI0NC0xLjIwNi0uNTU0LS4zMS0uMzA5LS41MDctLjc0NS0uNTkzLTEuMzFsMS4xMi0uMTJjLjA0Ny4yMy4xMzIuNDI3LjI1OC41OTMuMTI2LjE2Ni4yNjcuMjg2LjQyMS4zNlptMC00LjUwOWEuODA2LjgwNiAwIDAgMC0uNDA0LjI5MmMtLjEuMTM4LS4xNS4yOS0uMTUuNDU1YS43My43MyAwIDAgMCAuMTM3LjQyNS45Ni45NiAwIDAgMCAuNDE2LjMxNFY2LjM2Wm0uNjIyIDQuNTY5YS44NzguODc4IDAgMCAwIC41MjQtLjI5Ni44NDguODQ4IDAgMCAwIC4yMDItLjU2My43NDYuNzQ2IDAgMCAwLS4xNzItLjQ5NGMtLjExMi0uMTQtLjI5Ny0uMjQ3LS41NTQtLjMyMnYxLjY3NVoiLz48ZGVmcz48bGluZWFyR3JhZGllbnQgaWQ9ImEiIHgxPSI5IiB4Mj0iOSIgeTE9IjIiIHkyPSIxNiIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPjxzdG9wIG9mZnNldD0iLjAyMyIgc3RvcC1jb2xvcj0iI0I0NzAwMCIvPjxzdG9wIG9mZnNldD0iLjExIiBzdG9wLWNvbG9yPSIjREY4RjBCIi8+PHN0b3Agb2Zmc2V0PSIuNDIiIHN0b3AtY29sb3I9IiNGRkIxMzAiLz48c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiNGRkM5NkYiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48L3N2Zz4=)
    center left / 18px 18px
    no-repeat;
  color: var(--c-white);
}

span.coin-startrek {
  padding-left: 24px;
  background:
    url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHJlY3Qgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiB4PSIxIiB5PSIxIiBmaWxsPSIjRkZCMTMwIiByeD0iOCIvPjxyZWN0IHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgeD0iMiIgeT0iMiIgZmlsbD0idXJsKCNhKSIgcng9IjciLz48cGF0aCBmaWxsPSIjMTIxRDQxIiBkPSJNOS4xNSA0LjExNGMuNTI4LjcxNyAxLjg2IDIuNjA3IDIuNjE3IDQuNDQ1LjcwMSAxLjcwMy45NzQgMy40NzYgMS4wNzggNC40NjNhLjI3NS4yNzUgMCAwIDEtLjE1OC4yNzkuMjgzLjI4MyAwIDAgMS0uMzE5LS4wNTQgMTguMjU1IDE4LjI1NSAwIDAgMC0xLjQ1My0xLjI4NyAxNC41OSAxNC41OSAwIDAgMC0xLjg1NS0xLjIzNS4yODIuMjgyIDAgMCAwLS4yNzQgMGMtLjY0OS4zNjQtMS4yNjkuNzc3LTEuODU0IDEuMjM1LS41MDcuNDAzLS45OTMuODMzLTEuNDU0IDEuMjg3YS4yODEuMjgxIDAgMCAxLS40NzYtLjIyNWMuMTA0LS45ODcuMzc2LTIuNzYgMS4wNzgtNC40NjMuNzU3LTEuODM4IDIuMDg4LTMuNzI4IDIuNjE3LTQuNDQ1YS4yNzkuMjc5IDAgMCAxIC4zNTMtLjA4NGMuMDQuMDIuMDczLjA0OS4xLjA4NFoiLz48cGF0aCBmaWxsPSIjMTIxRDQxIiBkPSJNOC45NzcgMTEuNDczYy4xMjMuMDY4LjQzMi4yNDIuNjM2LjM4Ni4xNzUuMTIzLjM0Mi4yNTguNDk4LjQwNGEuMTA3LjEwNyAwIDAgMS0uMDMuMTc3LjExLjExIDAgMCAxLS4wNy4wMDVsLTEuMDYtLjI0MmEuMS4xIDAgMCAwLS4wNSAwbC0xLjA2LjI0MmEuMTA4LjEwOCAwIDAgMS0uMTMzLS4xMjIuMTA3LjEwNyAwIDAgMSAuMDM0LS4wNmMuMTU1LS4xNDYuMzItLjI4LjQ5NC0uNDA0YTcuOTEgNy45MSAwIDAgMSAuNjM2LS4zODYuMTA5LjEwOSAwIDAgMSAuMTA1IDBaIi8+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJhIiB4MT0iOSIgeDI9IjkiIHkxPSIyIiB5Mj0iMTYiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj48c3RvcCBvZmZzZXQ9Ii4wMjMiIHN0b3AtY29sb3I9IiNCNDcwMDAiLz48c3RvcCBvZmZzZXQ9Ii4xMSIgc3RvcC1jb2xvcj0iI0RGOEYwQiIvPjxzdG9wIG9mZnNldD0iLjQyIiBzdG9wLWNvbG9yPSIjRkZCMTMwIi8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjRkZDOTZGIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PC9zdmc+)
    center left / 18px 18px
    no-repeat;
  color: var(--c-white);
}
