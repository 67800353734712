:root {
  // z-orders
  --z-index-particles: 1;
  --z-index-lasers: 3;
  --z-index-decor-page: 4;
  --z-index-page: 10;
  --z-index-registration-form: 11;
  --z-index-womans: 12;
  --z-index-modal: 100;

  // colors
  --c-graphite-black: rgb(28, 28, 32);
  --c-brilliant-yellow: rgb(255, 183, 36);
  --c-white: rgb(255, 255, 255);
  --c-pastel-yellow: rgb(230, 183, 74);
  --c-dark-grey: rgb(40, 43, 48);

  //
  --app-gap: 20px;
  --app-wadth-min: 300px;
  --app-wadth-max: 1440px;
  --app-wadth: clamp(var(--app-wadth-min), calc(100vw - var(--app-gap)), var(--app-wadth-max));

  @media (width >= 992px) {
    --app-wadth: clamp(var(--app-wadth-min), calc(100vw - (var(--app-gap) * 2)), var(--app-wadth-max));
  }

  // offsets
  --v-offset-sm: max(4vmin, 20px);
  --v-offset-md: min(100px, 5vmax);
  --v-offset-lg: min(160px, 8vmax);

  // fonts
  --min-fs: 0.8;
  --max-fs: 1;
  --min-vw: 60;
  --max-vw: 160;

  --font-family-default: "Oswald";
  --default-line-height: 1.5;
  --heading-line-height: 1.3em;

  // scrollbar
  --c-scrollbar-track: var(--c-bg-page);
  --c-scrollbar-thumb: var(--c-dark-orchid);
  --c-scrollbar-hover: var(--c-dark-orchid);
}
