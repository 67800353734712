.modal {
  position: fixed;
  inset: 0;
  z-index: var(--z-index-modal);
  width: 100vw;
  min-height: 100%;
  overflow: hidden;

  &--in {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
  }

  &--out {
    pointer-events: none;

    #registration-form label,
    #registration-form span {
      pointer-events: none;
    }
  }

  &__backdrop {pointer-events: none;
    position: fixed;
    inset: 0;
    margin: auto;
    z-index: 1;

    .modal--in & {
      background-color: hsla(200 2% 6% / 0.62);
      transition: background-color 400ms cubic-bezier(.61, 1, .88, 1);
    }

    .modal--out & {
      pointer-events: none;
      background-color: hsla(200 2% 6% / 0);
      transition: background-color 500ms cubic-bezier(0, 0, 0.5, 1);
    }
  }

  &__container {
    pointer-events: none;
    position: relative;
    z-index: 2;
    display: grid;
    place-content: center;
    width: 100vw;
    min-height: 100vh;

    &-body {
      position: relative;
      margin: 30px;

      .modal--in & {
        pointer-events: auto;
        opacity: 1;
        transform: translateY(0);
        transition:
          opacity 400ms cubic-bezier(.61, 1, .88, 1),
          transform 400ms cubic-bezier(.61, 1, .88, 1);
      }

      .modal--out & {
        pointer-events: none;
        opacity: 0;
        transform: translateY(40px);
        transition:
          opacity 500ms cubic-bezier(0, 0, 0.5, 1),
          transform 550ms cubic-bezier(.22, 1, .5, .95);
      }
    }
  }

  &-close {
    display: none;
    //cursor: pointer;
    //position: absolute;
    //inset: 0 0 auto auto;
    //z-index: 1;
    //display: grid;
    //place-content: center;
    //width: 50px;
    //height: 50px;
    //
    //.svg-icon {
    //  width: 24px;
    //  height: 24px;
    //  fill: Black;
    //}
  }
}
