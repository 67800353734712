.click-counter {
  display: grid;
  grid-template: auto / auto 40px;
  gap: 14px;
  align-items: center;
  width: fit-content;
  margin-bottom: 20px;

  &__num {
    font-size: 38px;
    font-weight: 700;
    line-height: 1;
    color: var(--c-white);

    width: fit-content;
    //justify-self: end;
  }

  &__img {
    img {
      display: block;
      width: 42px;
      height: 42px;
      transform: translateY(2px);
    }
  }
}
