.image-container {
  width: 100%;
  height: 100%;

  picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &--hamster {
    position: absolute;
    inset: 0;
    z-index: 1;
    margin: auto;
    width: 72%;
    height: 72%;
    pointer-events: none;
    user-select: none;
  }
}
