.bonus-banner {
  padding: 20px 0;
  width: min(90vw, 500px);
  background-color: #F6F6F6;
  border-radius: 17.5px;

  img {
    display: block;
    margin: 0 auto 15px auto;
    width: 90%;
    height: auto;
    object-fit: cover;
    object-position: center;
  }

  .btn-violet {
    margin: auto;
    padding: 0 56px 2px 56px;
    width: fit-content;
    height: 44px;
    background: linear-gradient(to top, #D83502, #FF4910);
    color: var(--c-white);
    font-size: 16px;
    letter-spacing: .5px;
    white-space: nowrap;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    -webkit-user-select: none;
    user-select: none;
    touch-action: manipulation;
    place-content: center;
    min-width: fit-content;
    font-weight: 600;
    text-decoration: none;
    display: grid;
    border-radius: 4px;
  }
}
