html,
body {
  position: relative;
}

body {
  overflow: hidden auto;
  width: 100vw;
  background-color: #121D41;

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }

  @supports not (min-height: 100dvh) {
    min-height: 100vh;
  }
}

.page {
  overflow: hidden;
  position: relative;
  z-index: var(--z-index-page);
  display: grid;
  grid-template: auto auto 1fr / auto;
  gap: 0;
  place-content: start center;
  justify-items: center;
  height: 100%;

  @include min(phone-landscape) {

  }

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }

  @supports not (min-height: 100dvh) {
    min-height: 100vh;
  }

  &__logotype {

  }

  &__user {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0 12px;
    width: 100%;
    padding: 16px min(4vmin, 30px) 20px;

    .svg-icon {
      width: 42px;
      height: 42px;
    }
  }

  &__game {
    position: relative;
    width: min(100vw, 500px);
    background: linear-gradient(#F24511, var(--c-graphite-black));
    border-radius: 50px 50px 0 0;
    filter: drop-shadow(0 -22px 20px rgba(255, 183, 36, 0.25));
  }
}
