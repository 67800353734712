.title-medium {
  margin: 0 0 6px;
  text-align: center;
  font-size: 1.4rem;
  text-transform: uppercase;
  color: #00BEFF;
}

.headline-medium {
  margin: 0;
  line-height: 1;
  font-size: 2.8em;
  font-weight: 700;
  color: var(--c-white);
  text-transform: uppercase;
}
