:root {
  --max-fs: 1;
  --min-fs: .75;
  --max-vw: 120;
  --min-vw: 80;
  --min-fs-rem: calc(var(--min-fs) * 1rem);
  --max-fs-rem: calc(var(--max-fs) * 1rem);
  --min-vw-rem: calc(var(--min-vw) * 1rem);
  --slope: calc((var(--max-fs)  - var(--min-fs)) * (100vw - var(--min-vw-rem)) / (var(--max-vw)  - var(--min-vw)));
  --font-size-clamp: clamp(var(--min-fs-rem), calc(var(--min-fs-rem)  + var(--slope)), var(--max-fs-rem));
  --default-line-height: 1.5;
  --heading-line-height: 1.3em;
}

html {
  font-feature-settings: "kern" 1, "liga" 0;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: auto;
  box-sizing: border-box;
  font-size: 10px;
}

body {
  font-family: var(--font-family-default, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji");
  font-size: var(--font-size-clamp);
  line-height: var(--default-line-height);
  font-weight: 400;
}

*, :before, :after {
  box-sizing: inherit;
}

a {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

.display-large {
  font-size: 5.7em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.08em;
}

.display-medium {
  font-size: 4.5em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.07em;
}

.display-small {
  font-size: 3.6em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.07em;
}

.headline-large {
  font-size: 3.2em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.06em;
}

.headline-medium {
  font-size: 2.8em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.06em;
}

.headline-small {
  font-size: 2.4em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.05em;
}

.title-large {
  font-size: 2.2em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.04em;
}

.title-medium {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.03em;
  font-weight: 600;
}

.title-small {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  font-weight: 600;
}

.label-large {
  text-wrap: balance;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
}

.label-medium {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
}

.label-small {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.6rem;
}

.body-large {
  text-wrap: balance;
  margin-bottom: 1.2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.body-medium {
  text-wrap: balance;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  line-height: 2rem;
}

.body-small {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
}

ul, ol {
  padding-left: 0;
  font-size: 1.4rem;
  line-height: 2rem;
  list-style-position: outside;
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

pre, code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  appearance: none;
}

::-webkit-file-upload-button {
  appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

:root {
  --z-index-particles: 1;
  --z-index-lasers: 3;
  --z-index-decor-page: 4;
  --z-index-page: 10;
  --z-index-registration-form: 11;
  --z-index-womans: 12;
  --z-index-modal: 100;
  --c-graphite-black: #1c1c20;
  --c-brilliant-yellow: #ffb724;
  --c-white: #fff;
  --c-pastel-yellow: #e6b74a;
  --c-dark-grey: #282b30;
  --app-gap: 20px;
  --app-wadth-min: 300px;
  --app-wadth-max: 1440px;
  --app-wadth: clamp(var(--app-wadth-min), calc(100vw - var(--app-gap)), var(--app-wadth-max));
  --v-offset-sm: max(4vmin, 20px);
  --v-offset-md: min(100px, 5vmax);
  --v-offset-lg: min(160px, 8vmax);
  --min-fs: .8;
  --max-fs: 1;
  --min-vw: 60;
  --max-vw: 160;
  --font-family-default: "Oswald";
  --default-line-height: 1.5;
  --heading-line-height: 1.3em;
  --c-scrollbar-track: var(--c-bg-page);
  --c-scrollbar-thumb: var(--c-dark-orchid);
  --c-scrollbar-hover: var(--c-dark-orchid);
}

@media (width >= 992px) {
  :root {
    --app-wadth: clamp(var(--app-wadth-min), calc(100vw - (var(--app-gap) * 2)), var(--app-wadth-max));
  }
}

.title-medium {
  text-align: center;
  text-transform: uppercase;
  color: #00beff;
  margin: 0 0 6px;
  font-size: 1.4rem;
}

.headline-medium {
  color: var(--c-white);
  text-transform: uppercase;
  margin: 0;
  font-size: 2.8em;
  font-weight: 700;
  line-height: 1;
}

html, body {
  position: relative;
}

body {
  background-color: #121d41;
  width: 100vw;
  overflow: hidden auto;
}

@supports (min-height: 100dvh) {
  body {
    min-height: 100dvh;
  }
}

@supports not (min-height: 100dvh) {
  body {
    min-height: 100vh;
  }
}

.page {
  z-index: var(--z-index-page);
  grid-template: auto auto 1fr / auto;
  place-content: start center;
  justify-items: center;
  gap: 0;
  height: 100%;
  display: grid;
  position: relative;
  overflow: hidden;
}

@supports (min-height: 100dvh) {
  .page {
    min-height: 100dvh;
  }
}

@supports not (min-height: 100dvh) {
  .page {
    min-height: 100vh;
  }
}

.page__user {
  justify-content: flex-start;
  align-items: center;
  gap: 0 12px;
  width: 100%;
  padding: 16px min(4vmin, 30px) 20px;
  display: flex;
}

.page__user .svg-icon {
  width: 42px;
  height: 42px;
}

.page__game {
  background: linear-gradient(#f24511, var(--c-graphite-black));
  filter: drop-shadow(0 -22px 20px #ffb72440);
  border-radius: 50px 50px 0 0;
  width: min(100vw, 500px);
  position: relative;
}

#iconset {
  top: -9999px;
}

.image-container {
  width: 100%;
  height: 100%;
}

.image-container picture {
  width: 100%;
  height: 100%;
  display: flex;
}

.image-container img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.image-container--hamster {
  z-index: 1;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  width: 72%;
  height: 72%;
  margin: auto;
  position: absolute;
  inset: 0;
}

.image-container--page-background {
  width: min(100vw, 1920px);
  height: min(100vh, 1920px);
  margin: auto;
  position: fixed;
  inset: 0;
}

.modal {
  z-index: var(--z-index-modal);
  width: 100vw;
  min-height: 100%;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.modal--in {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

.modal--out, .modal--out #registration-form label, .modal--out #registration-form span {
  pointer-events: none;
}

.modal__backdrop {
  pointer-events: none;
  z-index: 1;
  margin: auto;
  position: fixed;
  inset: 0;
}

.modal--in .modal__backdrop {
  background-color: #0f0f109e;
  transition: background-color .4s cubic-bezier(.61, 1, .88, 1);
}

.modal--out .modal__backdrop {
  pointer-events: none;
  background-color: #0f0f1000;
  transition: background-color .5s cubic-bezier(0, 0, .5, 1);
}

.modal__container {
  pointer-events: none;
  z-index: 2;
  place-content: center;
  width: 100vw;
  min-height: 100vh;
  display: grid;
  position: relative;
}

.modal__container-body {
  margin: 30px;
  position: relative;
}

.modal--in .modal__container-body {
  pointer-events: auto;
  opacity: 1;
  transition: opacity .4s cubic-bezier(.61, 1, .88, 1), transform .4s cubic-bezier(.61, 1, .88, 1);
  transform: translateY(0);
}

.modal--out .modal__container-body {
  pointer-events: none;
  opacity: 0;
  transition: opacity .5s cubic-bezier(0, 0, .5, 1), transform .55s cubic-bezier(.22, 1, .5, .95);
  transform: translateY(40px);
}

.modal-close {
  display: none;
}

.hamster-click {
  border-radius: 100%;
  place-content: center;
  width: min(76vmin, 400px);
  height: min(76vmin, 400px);
  display: grid;
  position: relative;
}

.hamster-click .hamster-click__clicker {
  z-index: 4;
  border-radius: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
}

.hamster-click .hamster-click__clicker .click-num {
  -webkit-user-select: none;
  user-select: none;
  color: #fff;
  filter: drop-shadow(0 1px 1px #000);
  place-content: center;
  width: 50px;
  height: 50px;
  font-size: 4em;
  font-weight: 700;
  animation: .85s cubic-bezier(.47, 0, .745, .715) forwards fade-top;
  display: none;
  position: absolute;
}

@keyframes fade-top {
  0% {
    opacity: 1;
    transform: translate(-50%, -70%);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, calc(-70% - 95px));
  }
}

.hamster-click .hamster-click__image {
  z-index: 3;
  border-radius: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
}

.hamster-click .hamster-click__image img {
  object-fit: cover;
  object-position: center;
  z-index: 1;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  width: 88%;
  height: 88%;
  margin: auto;
  position: absolute;
  inset: 0;
}

.hamster-click .hamster-click__circle {
  z-index: 2;
  transform: skew(calc(4deg * var(--click-rate)), calc(4deg * var(--click-rate)));
  box-shadow: 0 0 50px 0 rgba(255, 183, 36, var(--click-rate));
  background: linear-gradient(#01f221, #000596);
  border-radius: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
}

.hamster-click .hamster-click__circle:after {
  pointer-events: none;
  content: "";
  z-index: 2323;
  background: radial-gradient(circle, #183cb9 25%, #070541 76%);
  border-radius: 100%;
  margin: auto;
  position: absolute;
  inset: min(2.6vmin, 16px);
  box-shadow: inset 0 0 30px #0003, 0 0 3px #0000004d;
}

.hamster-click .hamster-click__light {
  z-index: 1;
  border-radius: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
}

.logotype-crasher {
  padding: 20px 0 16px;
}

.logotype-crasher .svg-icon {
  width: 240px;
  height: 54px;
}

.click-counter {
  grid-template: auto / auto 40px;
  align-items: center;
  gap: 14px;
  width: fit-content;
  margin-bottom: 20px;
  display: grid;
}

.click-counter__num {
  color: var(--c-white);
  width: fit-content;
  font-size: 38px;
  font-weight: 700;
  line-height: 1;
}

.click-counter__img img {
  width: 42px;
  height: 42px;
  display: block;
  transform: translateY(2px);
}

.hamster-game {
  background: var(--c-graphite-black);
  border-radius: 48px 48px 0 0;
  grid-template: auto auto auto auto 1fr / auto;
  justify-items: center;
  height: 100%;
  margin: 2px 2px 0;
  padding: min(5vmin, 35px) min(4vmin, 30px) 0;
  display: grid;
  position: relative;
}

.bonus-list {
  grid-template: 1fr / repeat(3, 1fr);
  gap: 0 8px;
  width: 100%;
  margin-bottom: 20px;
  display: grid;
}

.bonus-list__item {
  background-color: var(--c-dark-grey);
  text-align: center;
  border-radius: 14.5px;
  grid-template: auto auto / auto;
  justify-items: center;
  padding: 9px 8px;
  font-size: 1.35rem;
  font-weight: 700;
  line-height: 1.5;
  display: grid;
}

.bonus-list__item:first-child {
  color: #f7460f;
}

.bonus-list__item:nth-child(2) {
  color: #0f6;
}

.bonus-list__item:nth-child(3) {
  color: #ffb130;
}

span.coin-dollar {
  color: var(--c-white);
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHJlY3Qgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiB4PSIxIiB5PSIxIiBmaWxsPSIjRkZCMTMwIiByeD0iOCIvPjxyZWN0IHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgeD0iMiIgeT0iMiIgZmlsbD0idXJsKCNhKSIgcng9IjciLz48cGF0aCBmaWxsPSIjMTIxRDQxIiBkPSJNOC44IDEwLjg3di0xLjhjLS41NjItLjE2LS45NzQtLjQwMi0xLjIzNy0uNzI1LS4yNjEtLjMyNi0uMzkxLS43MjEtLjM5MS0xLjE4NSAwLS40Ny4xNDctLjg2My40NDItMS4xODEuMjk4LS4zMi42OTMtLjUwNSAxLjE4NS0uNTU0VjVoLjYyM3YuNDI1Yy40NTUuMDU0LjgxNy4yMSAxLjA4Ni40NjguMjcuMjU1LjQ0MS41OTcuNTE1IDEuMDI2bC0xLjA4Ni4xNDJjLS4wNjYtLjMzOC0uMjM4LS41NjctLjUxNS0uNjg3djEuNjc5Yy42ODcuMTg2IDEuMTU1LjQyOCAxLjQwNC43MjYuMjQ5LjI5NS4zNzMuNjc0LjM3MyAxLjEzOCAwIC41MTgtLjE1Ny45NTQtLjQ3MiAxLjMxLS4zMTIuMzU0LS43NDcuNTcyLTEuMzA1LjY1MnYuODAzaC0uNjIzVjExLjljLS40OTUtLjA2LS44OTctLjI0NC0xLjIwNi0uNTU0LS4zMS0uMzA5LS41MDctLjc0NS0uNTkzLTEuMzFsMS4xMi0uMTJjLjA0Ny4yMy4xMzIuNDI3LjI1OC41OTMuMTI2LjE2Ni4yNjcuMjg2LjQyMS4zNlptMC00LjUwOWEuODA2LjgwNiAwIDAgMC0uNDA0LjI5MmMtLjEuMTM4LS4xNS4yOS0uMTUuNDU1YS43My43MyAwIDAgMCAuMTM3LjQyNS45Ni45NiAwIDAgMCAuNDE2LjMxNFY2LjM2Wm0uNjIyIDQuNTY5YS44NzguODc4IDAgMCAwIC41MjQtLjI5Ni44NDguODQ4IDAgMCAwIC4yMDItLjU2My43NDYuNzQ2IDAgMCAwLS4xNzItLjQ5NGMtLjExMi0uMTQtLjI5Ny0uMjQ3LS41NTQtLjMyMnYxLjY3NVoiLz48ZGVmcz48bGluZWFyR3JhZGllbnQgaWQ9ImEiIHgxPSI5IiB4Mj0iOSIgeTE9IjIiIHkyPSIxNiIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPjxzdG9wIG9mZnNldD0iLjAyMyIgc3RvcC1jb2xvcj0iI0I0NzAwMCIvPjxzdG9wIG9mZnNldD0iLjExIiBzdG9wLWNvbG9yPSIjREY4RjBCIi8+PHN0b3Agb2Zmc2V0PSIuNDIiIHN0b3AtY29sb3I9IiNGRkIxMzAiLz48c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiNGRkM5NkYiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48L3N2Zz4=") 0 / 18px 18px no-repeat;
  padding-left: 24px;
}

span.coin-startrek {
  color: var(--c-white);
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHJlY3Qgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiB4PSIxIiB5PSIxIiBmaWxsPSIjRkZCMTMwIiByeD0iOCIvPjxyZWN0IHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgeD0iMiIgeT0iMiIgZmlsbD0idXJsKCNhKSIgcng9IjciLz48cGF0aCBmaWxsPSIjMTIxRDQxIiBkPSJNOS4xNSA0LjExNGMuNTI4LjcxNyAxLjg2IDIuNjA3IDIuNjE3IDQuNDQ1LjcwMSAxLjcwMy45NzQgMy40NzYgMS4wNzggNC40NjNhLjI3NS4yNzUgMCAwIDEtLjE1OC4yNzkuMjgzLjI4MyAwIDAgMS0uMzE5LS4wNTQgMTguMjU1IDE4LjI1NSAwIDAgMC0xLjQ1My0xLjI4NyAxNC41OSAxNC41OSAwIDAgMC0xLjg1NS0xLjIzNS4yODIuMjgyIDAgMCAwLS4yNzQgMGMtLjY0OS4zNjQtMS4yNjkuNzc3LTEuODU0IDEuMjM1LS41MDcuNDAzLS45OTMuODMzLTEuNDU0IDEuMjg3YS4yODEuMjgxIDAgMCAxLS40NzYtLjIyNWMuMTA0LS45ODcuMzc2LTIuNzYgMS4wNzgtNC40NjMuNzU3LTEuODM4IDIuMDg4LTMuNzI4IDIuNjE3LTQuNDQ1YS4yNzkuMjc5IDAgMCAxIC4zNTMtLjA4NGMuMDQuMDIuMDczLjA0OS4xLjA4NFoiLz48cGF0aCBmaWxsPSIjMTIxRDQxIiBkPSJNOC45NzcgMTEuNDczYy4xMjMuMDY4LjQzMi4yNDIuNjM2LjM4Ni4xNzUuMTIzLjM0Mi4yNTguNDk4LjQwNGEuMTA3LjEwNyAwIDAgMS0uMDMuMTc3LjExLjExIDAgMCAxLS4wNy4wMDVsLTEuMDYtLjI0MmEuMS4xIDAgMCAwLS4wNSAwbC0xLjA2LjI0MmEuMTA4LjEwOCAwIDAgMS0uMTMzLS4xMjIuMTA3LjEwNyAwIDAgMSAuMDM0LS4wNmMuMTU1LS4xNDYuMzItLjI4LjQ5NC0uNDA0YTcuOTEgNy45MSAwIDAgMSAuNjM2LS4zODYuMTA5LjEwOSAwIDAgMSAuMTA1IDBaIi8+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJhIiB4MT0iOSIgeDI9IjkiIHkxPSIyIiB5Mj0iMTYiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj48c3RvcCBvZmZzZXQ9Ii4wMjMiIHN0b3AtY29sb3I9IiNCNDcwMDAiLz48c3RvcCBvZmZzZXQ9Ii4xMSIgc3RvcC1jb2xvcj0iI0RGOEYwQiIvPjxzdG9wIG9mZnNldD0iLjQyIiBzdG9wLWNvbG9yPSIjRkZCMTMwIi8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjRkZDOTZGIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PC9zdmc+") 0 / 18px 18px no-repeat;
  padding-left: 24px;
}

.fake-timer {
  color: var(--c-white);
  grid-template: auto / auto auto;
  place-self: baseline;
  gap: 0 10px;
  margin: 14px 0 10px;
  font-size: 17px;
  display: grid;
}

.fake-timer .svg-icon {
  width: 28px;
  height: 28px;
}

canvas#easy-confetti {
  pointer-events: none;
  z-index: 999;
  width: 100%;
  height: 100vh;
  position: fixed;
  inset: 0;
}

.dialog-lite {
  z-index: var(--z-index-dialog-lite, 992);
  width: 100vw;
  position: fixed;
  inset: 0;
  overflow: clip auto;
}

.dialog-lite--in {
  -webkit-overflow-scrolling: touch;
}

.dialog-lite--out {
  pointer-events: none;
}

.dialog-lite__backdrop {
  z-index: var(--z-index-dialog-lite-backdrop, 993);
  margin: auto;
  position: fixed;
  inset: 0;
}

.dialog-lite--in .dialog-lite__backdrop {
  background-color: #0c0c13d1;
  transition: background-color .4s cubic-bezier(.61, 1, .88, 1);
}

.dialog-lite--out .dialog-lite__backdrop {
  pointer-events: none;
  background-color: #0f0f1000;
  transition: background-color .5s cubic-bezier(0, 0, .5, 1);
}

.dialog-lite__container {
  pointer-events: none;
  z-index: var(--z-index-dialog-lite-container, 994);
  place-content: center;
  width: 100vw;
  display: grid;
  position: relative;
}

@supports (min-height: 100dvh) {
  .dialog-lite__container {
    min-height: 100dvh;
  }
}

@supports not (min-height: 100dvh) {
  .dialog-lite__container {
    min-height: 100vh;
  }
}

.dialog-lite__container-inner {
  margin: 20px;
  position: relative;
}

.dialog-lite--in .dialog-lite__container-inner {
  pointer-events: auto;
  opacity: 1;
  transition: opacity .4s cubic-bezier(.61, 1, .88, 1), transform .4s cubic-bezier(.61, 1, .88, 1);
  transform: translateY(0);
}

.dialog-lite--out .dialog-lite__container-inner {
  pointer-events: none;
  opacity: 0;
  transition: opacity .5s cubic-bezier(0, 0, .5, 1), transform .55s cubic-bezier(.22, 1, .5, .95);
  transform: translateY(40px);
}

.dialog-lite-close-button {
  cursor: pointer;
  place-content: center;
  width: 50px;
  height: 50px;
  display: grid;
  position: absolute;
  inset: 0 0 auto auto;
}

.dialog-lite-close-button .svg-icon {
  fill: #000;
  width: 24px;
  height: 24px;
}

.bonus-banner {
  background-color: #f6f6f6;
  border-radius: 17.5px;
  width: min(90vw, 500px);
  padding: 20px 0;
}

.bonus-banner img {
  object-fit: cover;
  object-position: center;
  width: 90%;
  height: auto;
  margin: 0 auto 15px;
  display: block;
}

.bonus-banner .btn-violet {
  color: var(--c-white);
  letter-spacing: .5px;
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
  appearance: none;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  background: linear-gradient(to top, #d83502, #ff4910);
  border-radius: 4px;
  place-content: center;
  width: fit-content;
  min-width: fit-content;
  height: 44px;
  margin: auto;
  padding: 0 56px 2px;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  display: grid;
}
/*# sourceMappingURL=index.f752f2e1.css.map */
