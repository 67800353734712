.fake-timer {
  display: grid;
  grid-template: auto / auto auto;
  gap: 0 10px;
  place-self: first baseline;
  margin: 14px 0 10px;
  font-size: 17px;
  color: var(--c-white);

  .svg-icon {
    width: 28px;
    height: 28px;
  }
}
