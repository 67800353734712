.hamster-game {
  position: relative;
  display: grid;
  grid-template: auto auto auto auto 1fr / auto;
  justify-items: center;
  margin: 2px 2px 0;
  padding: min(5vmin, 35px) min(4vmin, 30px) 0;
  height: 100%;
  background: var(--c-graphite-black);
  border-radius: 48px 48px 0 0;
}
