@mixin positioning {
  position: absolute;
  inset: 0;
  margin: auto;
  border-radius: 100%;
}

.hamster-click {
  position: relative;
  width: min(76vmin, 400px);
  height: min(76vmin, 400px);
  border-radius: 100%;
  display: grid;
  place-content: center;

  .hamster-click__clicker {
    @include positioning;

    z-index: 4;

    .click-num {
      position: absolute;
      display: none;
      place-content: center;
      width: 50px;
      height: 50px;

      user-select: none;

      font-weight: 700;
      font-size: 4em;
      color: White;
      filter: drop-shadow(0 1px 1px rgb(0, 0, 0));

      animation: fade-top 850ms cubic-bezier(0.47, 0, 0.745, 0.715) forwards;
    }

    @keyframes fade-top {
      0% {
        transform: translate(-50%, -70%);
        opacity: 1;
      }
      100% {
        transform: translate(-50%, calc(-70% - 95px));
        opacity: 0;
      }
    }
  }

  .hamster-click__image {
    @include positioning;

    z-index: 3;

    img {
      object-fit: cover;
      object-position: center;
      position: absolute;
      inset: 0;
      z-index: 1;
      margin: auto;
      width: 88%;
      height: 88%;
      pointer-events: none;
      user-select: none;
    }
  }

  .hamster-click__circle {
    @include positioning;

    z-index: 2;
    background: linear-gradient(#01F221, #000596);

    transform: skew(calc(4deg * var(--click-rate)), calc(4deg * var(--click-rate)));
    box-shadow: 0 0 50px 0 rgba(255, 183, 36, var(--click-rate));

    &::after {
      pointer-events: none;
      content: '';
      position: absolute;
      z-index: 2323;
      inset: min(2.6vmin, 16px);
      margin: auto;
      background: radial-gradient(circle at center, #183CB9 25%, #070541 76%);
      box-shadow:
        0 0 30px 0 inset rgba(0, 0, 0, 0.2),
        0 0 3px 0 rgba(0, 0, 0, 0.3);
      border-radius: 100%;
    }
  }

  .hamster-click__light {
    @include positioning;

    z-index: 1;
  }
}
